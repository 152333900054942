import React, { useEffect, useState } from "react";
import TableListCard from "../../../../components/TableListCard/TableListCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../../../../redux/CustomReport/CustomReportSlice";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingIndicator/Loading";
import NoDataView from "../../../../components/NoDataView/NoDataView";

const CustomReportTab = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { clientSpaceId, entityId, accountingFirmId } = useParams();
  // eslint-disable-next-line
  const [search, setSearch] = useState("");
  const { reports, isFetching } = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(getReports({ entityId, accountingFirmId, search }));
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <TableListCard title={"Custom Reoprts"}>
        {!isFetching && reports.length !== 0 && (
          <div style={{ padding: "20px" }}>
            <TableContainer
              style={{
                height: "70vh",
                overflowY: "auto",
                maxHeight: "60vh",
                maxWidth: {
                  xs: 260,
                  sm: 290,
                  md: 580,
                  lg: "inherit",
                },
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={"bold"}>S.N</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Report Name</Typography>
                    </TableCell>
                    <TableCell width={"30%"}>
                      <Typography fontWeight={"bold"}>
                        Report Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Created By</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Created At</Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography fontWeight={"bold"}>Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{data.id}</TableCell>
                        <TableCell>{data.reportName}</TableCell>
                        <TableCell>{data.reportDescription}</TableCell>
                        <TableCell>{data.createBy}</TableCell>
                        <TableCell>{data.createdAt}</TableCell>
                        <TableCell>
                          <Grid>
                            <Button
                              //   onClick={handleClick}
                              size="small"
                              color="error"
                              id={"more-" + data._id}
                              startIcon={<DeleteOutlineOutlinedIcon />}
                            >
                              Delete
                            </Button>
                            <Button
                              //   onClick={handleClick}
                              size="small"
                              id={"more-" + data._id}
                              startIcon={<FileDownloadOutlinedIcon />}
                            >
                              Load
                            </Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {isFetching && <Loading />}
        {!isFetching && reports.length === 0 && search !== "" && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            Search Results empty
          </div>
        )}
        {!isFetching && reports.length === 0 && search === "" && (
          <NoDataView
            hintText="Sorry, you haven't added any custom reports"
            buttonText="Add new custom report"
            height="60vh"
            showButton={false}
            // addFunction={() => setNewSheetOpen(true)}
          />
        )}
      </TableListCard>
    </div>
  );
};

export default CustomReportTab;

import {
  Avatar,
  Button,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./ClientSpaces.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  deleteClientSpace,
  getClientSpaces,
} from "../../../redux/ClientSpace/ClientSpaceSlice";
import { useNavigate, useParams } from "react-router-dom";
import TableListCard from "../../../components/TableListCard/TableListCard";
import { ColorPalette } from "../../../constants/colorPalette";
import NoDataView from "../../../components/NoDataView/NoDataView";
import Loading from "../../../components/LoadingIndicator/Loading";
import AddClientSpaceModal from "./AddClientSpaceModal/AddClientSpaceModal";
import EditClientSpaceModal from "./editClientSpace/EditClientSpaceModal";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { checkDataAvailibility } from "../../../helper/utils";
import { toTitleCase } from "../../../helper/titleCaseConverter";

export default function ClientSpaces() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { accountingFirmId } = useParams();
  const { clientSpaces, isFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const userData = useSelector((state) => state.user);

  const [, setType] = useState("add");
  const [anchorEl, setAnchorEl] = useState(null);
  const [editSpaceModalOpen, setEditSpaceModalOpen] = useState(false);
  const [deleteSpaceModalOpen, setDeleteSpaceModalOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(isFetching);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [initialLoadingState, setInitialLoadingState] = useState(false);

  useEffect(() => {
    if (!isFetching && !initialLoadingState) {
      setInitialLoadingState(true);
    }
  }, [isFetching, initialLoadingState]);

  useEffect(() => {
    dispatch(getClientSpaces({ accountingFirmId }));
  }, [accountingFirmId, dispatch]);

  function createData(spaces) {
    let list = [];
    spaces?.forEach((space) => {
      const manager = space?.users.find((user) => user.role === "MANAGER");
      const accountant = space?.users.find(
        (user) => user.role === "ACCOUNTANT"
      );

      const managerFullName =
        manager?.user?.first_name && manager?.user?.last_name
          ? `${manager?.user?.first_name} ${manager?.user?.last_name}`
          : "John Doe";

      const accountantFullName =
        accountant?.user?.first_name && accountant?.user?.last_name
          ? `${accountant?.user?.first_name} ${accountant?.user?.last_name}`
          : "John Doe";

      list.push({
        name: space?.clientSpaceName,
        id: space?._id,
        manager: managerFullName,
        accountant: accountantFullName,
        ...space,
      });
    });
    return list;
  }

  const rows = createData(clientSpaces);

  const handleMenuItemClick = (event, row) => {
    setAnchorEl({ [row?.id]: event.currentTarget });
    setEditData(row);
    setDeleteId(row?.id);
  };

  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };

  const handleEditModal = () => {
    setEditSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDeleteModal = () => {
    setDeleteSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDelete = () => {
    dispatch(deleteClientSpace({ accountingFirmId, id: deleteId }));
    setDeleteSpaceModalOpen(false);
  };

  return (
    <div style={{ marginRight: "2%", marginLeft: "2%" }}>
      <Grid container>
        {modalOpen && (
          <AddClientSpaceModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            accountingFirmId={accountingFirmId}
            rows={clientSpaces}
          />
        )}
        {editSpaceModalOpen && (
          <EditClientSpaceModal
            modalOpen={editSpaceModalOpen}
            setModalOpen={setEditSpaceModalOpen}
            editData={editData}
            rows={clientSpaces}
          />
        )}
        {deleteSpaceModalOpen && (
          <ConfirmModal
            message="Are you sure you want to delete clientspace?"
            confirmText="Yes"
            declineText="No"
            declineFunction={() => setDeleteSpaceModalOpen(false)}
            confirmFunction={handleDelete}
            modalOpen={deleteSpaceModalOpen}
            setModalOpen={setDeleteSpaceModalOpen}
            title={"Delete Clientspace"}
            tooltipMessage={
              "Delete Impacts (Entities, Spreadsheets, Xero Data) "
            }
          />
        )}
        <Grid container direction="column">
          <Typography
            id="text-welcomeBack"
            variant="h1"
            sx={{
              fontSize: { xs: 24, sm: 36 },
              fontWeight: "bold",
              color: ColorPalette.secondaryDark,
              marginTop: "2rem",
            }}
          >
            Welcome Back, {checkDataAvailibility(userData)?.name?.split(" ")[0]}
          </Typography>
        </Grid>
        {isFetching || !initialLoadingState ? (
          <Loading />
        ) : (
          <div style={{ width: "100%" }}>
            {!isFetching && rows.length !== 0 && (
              <div>
                <TableListCard
                  title={"Client Spaces"}
                  addTitle={"Add Client Space"}
                  setModalOpen={setModalOpen}
                  marginTop={3}
                  setType={setType}
                  buttonId={"addSpace"}
                  titleId={"clientSpaces"}
                >
                  <Grid container>
                    <Grid item xs={12} marginTop={1}>
                      <div>
                        <TableContainer
                          style={{
                            overflowY: "auto",
                            maxHeight: "60vh",
                            maxWidth: {
                              xs: 260,
                              sm: 290,
                              md: 580,
                              lg: "inherit",
                            },
                          }}
                        >
                          <Table>
                            <TableHead
                              style={{
                                position: "sticky",
                                top: "0",
                                zIndex: 1,
                                backgroundColor: ColorPalette.white,
                              }}
                            >
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight={"bold"}
                                  >
                                    Client Space Name
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight={"bold"}
                                  >
                                    Created At
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight={"bold"}
                                  >
                                    Manager
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight={"bold"}
                                  >
                                    Accountant
                                  </Typography>
                                </TableCell>
                                <TableCell className="table-header"></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {rows.map((row, index) => (
                                <TableRow
                                  key={row.name + index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {toTitleCase(row.name)}
                                  </TableCell>
                                  <TableCell>
                                    {row?.createdAt?.split("T")[0]}
                                  </TableCell>
                                  <TableCell>
                                    <CardHeader
                                      style={{ padding: "0px" }}
                                      avatar={
                                        <Avatar
                                          style={{
                                            backgroundColor: `#0${row.manager.charCodeAt(
                                              0
                                            )}`,
                                            color: "white",
                                          }}
                                        >
                                          {row.manager
                                            ? row.manager
                                                .charAt(0)
                                                .toUpperCase()
                                            : "J"}
                                        </Avatar>
                                      }
                                      title={row.manager}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <CardHeader
                                      style={{ padding: "0px" }}
                                      avatar={
                                        <Avatar
                                          style={{
                                            backgroundColor: `#D${row.accountant.charCodeAt(
                                              0
                                            )}`,
                                            color: "white",
                                          }}
                                        >
                                          {row.accountant
                                            ? row.accountant
                                                .charAt(0)
                                                .toUpperCase()
                                            : "J"}
                                        </Avatar>
                                      }
                                      title={row.accountant}
                                    />
                                  </TableCell>
                                  <TableCell align="right" width={"20%"}>
                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"right"}
                                    >
                                      <Button
                                        id={`button-enter[${row?.id}]`}
                                        style={{
                                          textTransform: "none",
                                          marginRight: 30,
                                        }}
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                          navigate(
                                            `/accountingFirm/${accountingFirmId}/clientSpace/${row.id}/entities`
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                      <IconButton
                                        id="button-clientEntityView"
                                        sx={{
                                          height: 40,
                                          width: 40,
                                        }}
                                        color="inherit"
                                      >
                                        <SettingsOutlinedIcon
                                          style={{
                                            color: "grey",
                                            fontSize: 20,
                                          }}
                                          onClick={(event) =>
                                            handleMenuItemClick(event, row)
                                          }
                                        />
                                      </IconButton>
                                      <Menu
                                        anchorEl={anchorEl?.[row?.id]}
                                        open={Boolean(anchorEl?.[row?.id])}
                                        onClose={handleMenuItemClose}
                                      >
                                        <MenuItem onClick={handleEditModal}>
                                          <Typography className="menuItemTypography">
                                            <EditOutlinedIcon className="mr-10" />
                                            Edit
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={handleDeleteModal}>
                                          <Typography
                                            style={{ color: "#D33030" }}
                                            className="menuItemTypography"
                                          >
                                            <DeleteOutlineOutlinedIcon className="mr-10" />
                                            Delete
                                          </Typography>
                                        </MenuItem>
                                      </Menu>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Grid>
                  </Grid>
                </TableListCard>
              </div>
            )}
            {!isFetching && rows.length === 0 && (
              <NoDataView
                hintText="Sorry you haven't added any client space"
                buttonText="Add Client Space"
                addFunction={setModalOpen}
                showButton={true}
              />
            )}
          </div>
        )}
      </Grid>
    </div>
  );
}

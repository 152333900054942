import { handleRequest } from "../../helper/utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addReport = createAsyncThunk(
  "report/add",
  async ({ payload, entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${entityId}/create`;
    return handleRequest(endpoint, payload, "report", thunkAPI, null, "POST");
  }
);

export const duplicateReport = createAsyncThunk(
  "report/duplicate",
  async ({ payload, entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${entityId}/duplicate`;
    return handleRequest(
      endpoint,
      payload,
      "duplicateReport",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const getReports = createAsyncThunk(
  "report/getReports",
  async ({ accountingFirmId, entityId, search }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${entityId}?search=${search}`;
    return handleRequest(endpoint, null, "getReports", thunkAPI, null, "GET");
  }
);

export const getReport = createAsyncThunk(
  "report/getReport",
  async ({ entityId, reportId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${entityId}/${reportId}`;
    return handleRequest(endpoint, null, "getReport", thunkAPI, null, "GET");
  }
);

export const updateReport = createAsyncThunk(
  "report/update",
  async ({ payload, reportId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${reportId}`;
    return handleRequest(
      endpoint,
      payload,
      "updateReport",
      thunkAPI,
      null,
      "PATCH",
      true
    );
  }
);

export const deleteReport = createAsyncThunk(
  "report/delete",
  async ({ reportId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/reports/${reportId}`;
    return handleRequest(
      endpoint,
      null,
      "deleteReport",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);

const initialState = {
  isFetching: true,
  isSuccess: false,
  isError: false,
  reports: [],
  report: null,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReport.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.reports.unshift(payload.report);
        return state;
      })
      .addCase(addReport.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(addReport.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(duplicateReport.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.reports.unshift(payload.report);
        return state;
      })
      .addCase(duplicateReport.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(duplicateReport.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getReports.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.reports = payload.reports;
        return state;
      })
      .addCase(getReports.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(getReports.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getReport.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.report = payload.report;
        return state;
      })
      .addCase(getReport.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(getReport.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(deleteReport.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        let index = state.reports
          .map((report) => report._id)
          .indexOf(payload.deletedReport.id);
        state.reports.splice(index, 1);
        return state;
      })
      .addCase(deleteReport.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(deleteReport.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(updateReport.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.report = payload.report.data;
        return state;
      })
      .addCase(updateReport.rejected, (state) => {
        state.isRefreshing = false;
        state.isError = true;
        return state;
      })
      .addCase(updateReport.pending, (state) => {
        return state;
      });
  },
});
export const { reset } = reportSlice.actions;

export default reportSlice.reducer;

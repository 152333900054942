import React from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { updateClientSpace } from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { useParams } from "react-router-dom";
import { ColorPalette } from "../../../../constants/colorPalette";

const EditClientSpaceModal = ({ modalOpen, setModalOpen, editData, rows }) => {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateClientSpace({
        accountingFirmId,
        id: editData._id,
        data: { clientSpaceName: values.spaceName },
      })
    );
    setSubmitting(false);
    handleModalClose();
  };

  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
        maxWidth="md"
      >
        <Grid container flexDirection={"column"}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography fontSize={20} fontWeight="bold">
              Edit Accounting Firm
            </Typography>
            <IconButton
              id="button-addGroupCloseIcon"
              size="small"
              onClick={() => {
                handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Formik
          initialValues={{
            spaceName: editData?.name || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.spaceName) {
              errors.isSpaceNameEmpty = true;
              errors.spaceName = "Please enter clientspace name";
            } else if (
              rows
                .filter((row) => row.clientSpaceName !== editData.name)
                .some(
                  (row) =>
                    row.clientSpaceName.toLocaleLowerCase() ===
                    values.spaceName.toLocaleLowerCase().trim()
                )
            ) {
              errors.spaceName =
                "This clientspace name has already been used. Please use different name.";
            } else if (/[!@#$%^&*]/g.test(values.spaceName)) {
              errors.spaceName = "Special characters not allowed";
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container p={2}>
                <Grid item xs={6} marginTop={2}>
                  <Typography
                    id="text-addGroupName"
                    fontSize={16}
                    color={ColorPalette.black}
                    className="mb-10"
                  >
                    Client Space Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="spaceName"
                    value={values.spaceName}
                    error={!!errors.spaceName && !!touched.spaceName}
                    helperText={touched.spaceName && errors.spaceName}
                    defaultValue={editData?.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "35px",
                    gap: "10px",
                  }}
                >
                  <Button
                    id="button-confirmModalYes"
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      minWidth: "90px",
                      height: "35px",
                    }}
                    onClick={() => setModalOpen(false)}
                  >
                    <Typography sx={{ paddingX: "5px", textTransform: "none" }}>
                      Cancel
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    <Typography
                      sx={{ paddingX: "15px", textTransform: "none" }}
                    >
                      Save
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CustomModal>
    </div>
  );
};

export default EditClientSpaceModal;

import dayjs from "dayjs";

export const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: 700,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 3,
};

export const DEFAULT_CELL_VALUES = [
  "S.N",
  "Action",
  "Metric Name",
  "Value Type",
  "Metric Type",
  "Calculation Type",
  "Account Mapping",
  "Financial Year",
  "12 Month Avg",
  "6 Month Avg",
  "3 Month Avg",
  "Map Data",
];

export const VALUE_TYPES = [
  { label: "Currency", value: "Currency" },
  { label: "Integer", value: "Integer" },
  { label: "Decimal", value: "Decimal" },
  { label: "Percentage", value: "Percentage" },
];

export const REFERENCE_TABS = [
  { label: "Revenue", value: "revenue" },
  { label: "Expense", value: "expense" },
  { label: "People", value: "people" },
];

export const METRIC_TYPES = [
  { label: "Calculation", value: "Calculation" },
  { label: "Xero", value: "Xero" },
];

export const ACCOUNT_METRIC_TYPES = [
  { label: "Input", value: "Input" },
  { label: "Calculation", value: "Calculation" },
  { label: "Reference", value: "Reference" },
];

export const CALCULATION_TYPES = [
  { label: "Add", value: "Add" },
  { label: "Subtract", value: "Subtract" },
  { label: "Multiply", value: "Multiply" },
  { label: "Divide", value: "Divide" },
];

export const DEFAULT_KEYS = [
  "calculationType",
  "id",
  "metricName",
  "valueType",
  "metricType",
  "calculationType",
  "accountMapping",
  "parentId",
  "isExpanded",
  "financialYearData",
  "twelveMonthsData",
  "sixMonthsData",
  "threeMonthsData",
  "sameMonthPriorYearData",
  "rowType",
  "parentId",
  "subParentId",
  "sortOrder",
  "disabled",
  "forecastTimings",
  "priorPeriod",
  "priorPeriodType",
  "priorPeriodValue",
  "startDate",
  "endDate",
  "initialValue",
  "priorPeriodIncrementType",
  "accountMetrics",
  "action",
  "sno",
  "initialSave",
  "metricParentId",
  "formulaId",
  "referenceId",
  "referenceTab",
  "metricSortOrder",
  "manualAccountMetric",
  "employeeName",
  "weeklyHours",
  "annualSalary",
  "payRiseHistory",
  "bonusHistory",
  "hourlyRate",
  "paymentType",
  "changeHistory",
  "terminated",
  "terminationDate",
  "fte",
  "xeroSynced",
  "title",
  "refId",
  "groupParentId",
  "metric",
  "ppp",
  "annualSalaries",
  "showInCash",
  "gstApplicable",
  "cashTiming",
  "visible",
  "mapping",
  "stslApplied",
  "taxFreeThresholdApplied",
  "superannuationMapping",
];

export const DEFAULT_COLUMNS = [
  "metricName",
  "valueType",
  "metricType",
  "calculationType",
  "accountMapping",
  "sno",
  "action",
];

export const MONTH_MAP = {
  1: "jan",
  2: "feb",
  3: "mar",
  4: "apr",
  5: "may",
  6: "jun",
  7: "jul",
  8: "aug",
  9: "sep",
  10: "oct",
  11: "nov",
  12: "dec",
};

export const MONTH_MAP_REVERSE = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
};

export const ROW_TYPE = {
  TOTAL_ROW: "TOTAL_ROW",
  SUB_TOTAL_ROW: "SUB_TOTAL_ROW",
  TITLE_ROW: "TITLE_ROW",
  CUSTOM_METRIC: "CUSTOM_METRIC",
  ACCOUNT_GROUP: "ACCOUNT_GROUP",
  ACCOUNT: "ACCOUNT",
  METRIC: "METRIC",
};

export const MAPPING_TYPES = [
  { label: "Last Financial Year Average", value: "financialYearData" },
  { label: "Last Twelve Months Average", value: "twelveMonthsData" },
  { label: "Last Six Months Average", value: "sixMonthsData" },
  { label: "Last Three Months Average", value: "threeMonthsData" },
  { label: "Same Month Prior Year", value: "sameMonthPriorYearData" },
];

export const PRIOR_PERIOD_TYPES = [
  { label: "%", value: "percent" },
  { label: "$", value: "amount" },
];

export const TIMING_TYPES = [
  { label: "One time change", value: "oneTimeChange" },
  { label: "Increase over time", value: "increaseOverTime" },
  { label: "Growth change", value: "growthChange" },
];

export const TIMING_MAP = {
  oneTimeChange: "One Time Change",
  increaseOverTime: "Increase over time",
  growthChange: "Growth change",
};

export const PRIOR_PERRIOD_KEYS = [
  "financialYearData",
  "twelveMonthsData",
  "sixMonthsData",
  "threeMonthsData",
];

export const MAPPING_COLUMNS_HEADERS = [
  { text: "Financial Year", type: "header" },
  { text: "12 Month Avg", type: "header" },
  { text: "6 Month Avg", type: "header" },
  { text: "3 Month Avg", type: "header" },
];

export const MAPPING_COLUMNS = [
  { columnId: "financialYearData", width: 200, resizable: true },
  { columnId: "twelveMonthsData", width: 200, resizable: true },
  { columnId: "sixMonthsData", width: 200, resizable: true },
  { columnId: "threeMonthsData", width: 200, resizable: true },
];

export const METRIC_SETTING_COLUMNS = [
  { columnId: "valueType", width: 150, resizable: true },
  { columnId: "metricType", width: 150, resizable: true },
  { columnId: "calculationType", width: 150, resizable: true },
  { columnId: "accountMapping", width: 300, resizable: true },
];

export const METRIC_SETTING_HEADERS = [
  { text: "Value Type", type: "header" },
  { text: "Metric Type", type: "header" },
  { text: "Calculation Type", type: "header" },
  { text: "Account Mapping", type: "header" },
];

export const PEOPLE_METRIC_SETTING_COLUMNS = [
  { columnId: "weeklyHours", width: 150, resizable: true },
  { columnId: "fte", width: 150, resizable: true },
  { columnId: "startDate", width: 150, resizable: true },
  { columnId: "endDate", width: 150, resizable: true },
  { columnId: "annualSalary", width: 150, resizable: true },
  { columnId: "accountMapping", width: 150, resizable: true },
];

export const PEOPLE_METRIC_SETTING_HEADERS = [
  { text: "Weekly Hours", type: "header" },
  { text: "FTE", type: "header" },
  { text: "Start Date", type: "header" },
  { text: "End Date", type: "header" },
  { text: "Annual Salary ($)", type: "header" },
  { text: "Account Mapping", type: "header" },
];

export const defaultAccountMetricValues = () => {
  return {
    metricName: "",
    metricType: "Input",
    valueType: "Currency",
    initialValue: 0,
    forecastTimings: [],
    formulaId: [],
  };
};

export const defaultForecastValues = (start = null, end = null) => {
  return {
    name: "",
    start: start || dayjs().toISOString()?.slice(0, 7),
    end: end || null,
    type: "oneTimeChange",
    changeType: "increase",
    changeValue: 0,
    changeFormat: "percent",
    changeDuration: 1,
  };
};

export const defaultModalValues = {
  message: "",
  singleButton: false,
  confirmText: "Yes",
  declineText: "No",
  clearType: "normal",
  modalType: "",
  idValue: null,
};

export const DEFAULT_PEOPLE_COLUMNS = [
  "annualSalary",
  "endDate",
  "startDate",
  "fte",
  "weeklyHours",
  "employeeName",
  "accountMapping",
  "sno",
  "action",
];

export const DEFAULT_CASH_COLUMNS = ["sno", "title"];

export const DEFAULT_PEOPLE_CELL_VALUES = [
  "S.N",
  "Action",
  "Employee Name",
  "Weekly Hours",
  "FTE",
  "Start Date",
  "End Date",
  "Annual Salary ($)",
  "Account Mapping",
];

import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { getCustomProfitLossData } from "../../../../redux/ProfitandLoss/ProfitandLossSlice";
import {
  // Cell,
  // ColumnDef,
  // Header,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import "./CustomReportPlayground.css";
import {
  DragIndicator,
  // Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
  SettingsOutlined,
  SwapVert,
} from "@mui/icons-material";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
  KeyboardSensor,
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";

import {
  SortableContext,
  rectSortingStrategy,
  horizontalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableGrid from "../../SpreadSheet/components/SortableContainer/SortableGrid/SortableGrid";
import SortableItem from "../../SpreadSheet/components/SortableContainer/SortableItem/SortableItem";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import _, { uniqueId } from "lodash";
import { getFormulaSymbol } from "../../SpreadSheet/helper";
import { AntSwitch } from "../../SpreadSheet/Expense/Expense";
import Loading from "../../../../components/LoadingIndicator/Loading";

const { RangePicker } = DatePicker;
const COLUMN_TYPES = { CURRENCY: "Currency", PERCENTAGE: "Percentage" };
const COLUMN_DATA_TYPES = {
  XERO: "Xero",
  SPREADSHEET: "Spreadsheet",
  FORMULA: "Formula",
};
const DATE_RANGE_TYPES = {
  MONTH: "Month",
  CUSTOM: "Custom",
};

const CALCULATION_TYPES = ["Add", "Subtract", "Multiply", "Divide"];
const DraggableTableHeader = ({ header, columns, className }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });
  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };
  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={className}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {header.id !== "name" && columns.length > 2 && (
        <Button {...attributes} {...listeners}>
          <DragIndicator />
        </Button>
      )}
    </th>
  );
};
const DragAlongCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td style={style} ref={setNodeRef} className={className}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const CustomReportPlayGround = () => {
  const dispatch = useDispatch();
  const { entityId, accountingFirmId } = useParams();
  const [reportData, setReportData] = useState({});
  const date = moment().subtract(1, "month");
  const [anchorEl, setAnchorEl] = useState(null);
  const [addType, setAddType] = useState("row");
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [expanded, setExpanded] = React.useState({});
  const [columnOrder, setColumnOrder] = React.useState([]);

  const [dateFilter, setDateFilter] = useState({
    start: date.format("YYYY-MM"),
    end: date.format("YYYY-MM"),
  });
  const [sideNavData, setSideNavData] = useState({
    name: "",
    type: COLUMN_TYPES.CURRENCY,
    dataSource: COLUMN_DATA_TYPES.XERO,
    dateType: DATE_RANGE_TYPES.MONTH,
  });
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const sensors1 = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  const [allowSortOrderFormula, setAllowSortOrderFormula] = useState(false);
  const [formulaList, setFormulaList] = useState([]);

  //   const [search, setSearch] = useState("");
  //   const { reports, isFetching } = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(
      getCustomProfitLossData({
        entityId,
        accountingFirmId,
        start: dateFilter.start,
        end: dateFilter.end,
      })
    );
    // eslint-disable-next-line
  }, [dateFilter]);

  const { customPlData, isFetching } = useSelector(
    (state) => state.profitAndLoss
  );
  console.log(isFetching);
  const rowData = (rows) => {
    let arrangedData = [];
    rows.forEach((row) => {
      if (
        row.rowType === "INITIAL" ||
        row.rowType === "FORMULA" ||
        row.rowType === "METRIC"
      ) {
        let subRows = [];
        if (row.rowType === "METRIC") {
          let childrenRows = rows.filter((val) => val.parentId === row.id);
          childrenRows.forEach((child) => {
            if (
              (child.rowType === "ACCOUNT" || child.rowType === "TOTAL") &&
              !child.subParentId
            ) {
              subRows.push(child);
            }
            if (child.rowType === "GROUP") {
              let subChildrenRows = rows.filter(
                (val) => val.subParentId === child.id
              );
              subRows.push({ ...child, subRows: subChildrenRows });
            }
          });
        }
        arrangedData.push({ ...row, subRows });
      }
    });
    return arrangedData;
  };

  // const handleEditColumn = (val) => {
  //   console.log(val);
  // };

  // const handleAddColumns = () => {};

  useEffect(() => {
    if (customPlData) {
      setReportData({
        columns: Object.keys(customPlData.columns).map((key) => {
          return key !== "name"
            ? {
                accessorKey: key,
                id: key,
                size: 150,
                value: customPlData?.columns?.[key],
                header: ({ table }) => (
                  // <div style={{ display: "table", paddingTop: "5px" }}>
                  <span>{customPlData?.columns?.[key]}</span>
                  //   <IconButton
                  //     size="small"
                  //     {...{
                  //       onClick: () => handleEditColumn(key),
                  //     }}
                  //   >
                  //     <Edit />
                  //   </IconButton>
                  // </div>
                ),
              }
            : {
                size: 350,
                accessorKey: "name",
                header: ({ table }) => (
                  <div style={{ display: "table", paddingTop: "5px" }}>
                    Name
                    <IconButton
                      size="small"
                      {...{
                        onClick: table.getToggleAllRowsExpandedHandler(),
                      }}
                    >
                      {table.getIsAllRowsExpanded() ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </IconButton>
                  </div>
                ),
                cell: ({ row, getValue }) => (
                  <div
                    style={{
                      paddingLeft:
                        row.original.rowType === "TOTAL"
                          ? "0rem"
                          : row.original.rowType === "GROUP_TOTAL"
                          ? `2rem`
                          : `${row.depth * 2}rem`,
                      paddingTop: "3px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>{getValue()}</div>
                      {row.getCanExpand() && (
                        <IconButton
                          className="mt-neg-7-5"
                          size="small"
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: "pointer" },
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </IconButton>
                      )}
                    </div>
                  </div>
                ),
                footer: (props) => props.column.id,
              };
        }),
        rows: rowData(
          customPlData.rows?.filter(
            (row) => row.name !== " " && row.name !== ""
          )
        ),
        values: customPlData.rows?.filter(
          (row) => row.name !== " " && row.name !== ""
        ),
      });
      setColumnOrder(Object.keys(customPlData.columns)?.map((key) => key));
    }
    // eslint-disable-next-line
  }, [customPlData]);

  const table = useReactTable({
    data: reportData.rows,
    columns: reportData.columns,
    state: {
      expanded,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSideNav = () => {
    setSideNavOpen(false);
    setFormulaList([]);
    setSideNavData({
      name: "",
      type: COLUMN_TYPES.CURRENCY,
      dataSource: COLUMN_DATA_TYPES.XERO,
      dateType: DATE_RANGE_TYPES.MONTH,
    });
    setAllowSortOrderFormula(false);
  };

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (over?.id) {
        setFormulaList((items) => {
          let oldIndex = items.findIndex((item) => item.id === active.id);
          let newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  }, []);
  const handleDragEnd1 = useCallback((event) => {
    const { active, over } = event;
    if (over.id === "name") {
      return;
    }
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }, []);
  const handleSideNavChanges = (type, value) => {
    switch (type) {
      case "name":
        setSideNavData({ ...sideNavData, name: value });
        break;
      case "type":
        setSideNavData({ ...sideNavData, type: value });
        break;
      case "dataSource":
        setSideNavData({ ...sideNavData, dataSource: value });
        break;
      case "dateType":
        setSideNavData({ ...sideNavData, dateType: value });
        break;
      case "start":
        setSideNavData({ ...sideNavData, start: value });
        break;
      case "date":
        setSideNavData({ ...sideNavData, start: value.start, end: value.end });
        break;
      default:
        break;
    }
  };
  const handleSideNavSave = () => {
    // let list = formulaList.map((formula) => formula.type);
  };

  return (
    <div className="p-10 mt-20 ">
      <div>
        <Drawer
          anchor={"right"}
          open={sideNavOpen}
          onClose={handleCloseSideNav}
        >
          <Box sx={{ width: "600px", paddingTop: "70px" }}>
            <Grid container className="p-10">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Add {addType}
                </Typography>
              </Grid>
              <Grid item xs={12} className="mt-10">
                <Grid container>
                  <Grid item xs={8}>
                    <InputLabel className=" font-label mb-10">
                      Name:{" "}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={sideNavData.name}
                      onChange={({ target }) => {
                        handleSideNavChanges("name", target.value);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={8} className="mt-10">
                    <InputLabel className=" font-label mb-10" id="type-label">
                      Value Type:
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      id="input-type"
                      value={sideNavData?.type}
                      labelId="type-label"
                      onChange={({ target }) => {
                        handleSideNavChanges("type", target.value);
                      }}
                    >
                      {Object.keys(COLUMN_TYPES)?.map((key) => {
                        return (
                          <MenuItem key={key} value={COLUMN_TYPES[key]}>
                            {COLUMN_TYPES[key]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  {addType === "column" && (
                    <Grid item xs={8} className="mt-10">
                      <InputLabel className=" font-label mb-10" id="type-label">
                        Data Source:
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        id="input-type"
                        value={sideNavData?.dataSource}
                        labelId="data-source-label"
                        onChange={({ target }) => {
                          handleSideNavChanges("dataSource", target.value);
                        }}
                      >
                        {Object.keys(COLUMN_DATA_TYPES)?.map((key) => {
                          return (
                            <MenuItem key={key} value={COLUMN_DATA_TYPES[key]}>
                              {COLUMN_DATA_TYPES[key]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}
                  {addType === "row" && (
                    <Grid item xs={12} className="mt-10">
                      <InputLabel
                        className=" font-label mb-10"
                        id="operand-label"
                      >
                        Row Placement:
                      </InputLabel>
                      <Grid container>
                        <Grid item xs={4}>
                          <Grid
                            container
                            className="mt-10"
                            justifyContent={"flex-start"}
                          >
                            <Typography>Before</Typography>
                            <AntSwitch
                              onChange={() =>
                                handleSideNavChanges(
                                  "gstApplicable",
                                  !sideNavData?.gstApplicable
                                )
                              }
                            />
                            <Typography>After</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <Select
                            fullWidth
                            size="small"
                            id="input-operands"
                            labelId="operand-label"
                            value={null}
                            defaultValue={null}
                          >
                            {reportData.values?.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(sideNavData.dataSource === COLUMN_DATA_TYPES.FORMULA ||
                    addType === "row") && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        border: "1px grey solid",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                      className="mt-20"
                    >
                      <Grid container>
                        {" "}
                        <Grid item xs={12} className="mt-20">
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Formula
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              {" "}
                              <InputLabel
                                className=" font-label mb-10"
                                id="operand-label"
                              >
                                Operands:
                              </InputLabel>
                              <Select
                                fullWidth
                                size="small"
                                id="input-operands"
                                labelId="operand-label"
                                value={null}
                                defaultValue={null}
                                onChange={({ target }) => {
                                  setFormulaList([
                                    ...formulaList,
                                    {
                                      id: uniqueId(),
                                      name: target.value,
                                      type: "operand",
                                    },
                                  ]);
                                }}
                              >
                                {(addType === "column"
                                  ? reportData?.columns
                                  : reportData.values
                                )
                                  ?.filter((item) =>
                                    addType === "column"
                                      ? item.accessorKey !== "name"
                                      : true
                                  )
                                  ?.map((item) => {
                                    return (
                                      <MenuItem
                                        key={
                                          addType === "column"
                                            ? item.accessorKey
                                            : item.id
                                        }
                                        value={
                                          addType === "column"
                                            ? item.value
                                            : item.name
                                        }
                                      >
                                        {addType === "column"
                                          ? item.value
                                          : item.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <InputLabel
                                className=" font-label mb-10"
                                id="operator-label"
                              >
                                Operators:
                              </InputLabel>
                              <Grid container spacing={2}>
                                {CALCULATION_TYPES?.map((item) => {
                                  return (
                                    <Grid item xs={3}>
                                      <Button
                                        onClick={() => {
                                          setFormulaList([
                                            ...formulaList,
                                            {
                                              id: uniqueId(),
                                              name: getFormulaSymbol(item),
                                              type: "operator",
                                            },
                                          ]);
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {getFormulaSymbol(item)}
                                      </Button>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                className="mt-30"
                                onClick={() => {
                                  setAllowSortOrderFormula(true);
                                }}
                              >
                                <SwapVert />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        {formulaList.length !== 0 && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              border: "1px grey solid",
                              borderRadius: "5px",
                              padding: 10,
                            }}
                            className="mt-20"
                          >
                            <DndContext
                              sensors={sensors}
                              collisionDetection={closestCenter}
                              onDragEnd={handleDragEnd}
                            >
                              <SortableContext
                                disabled={!allowSortOrderFormula}
                                items={formulaList}
                                strategy={rectSortingStrategy}
                              >
                                <SortableGrid columns={4}>
                                  {formulaList.map((formula) => {
                                    return (
                                      <SortableItem
                                        key={formula?.id}
                                        id={formula?.id}
                                        name={formula?.name}
                                        disabled={!allowSortOrderFormula}
                                        onDelete={() => {
                                          let index = formulaList
                                            .map((formula) => formula.id)
                                            .indexOf(formula.id);
                                          let val = _.cloneDeep(formulaList);
                                          val.splice(index, 1);
                                          setFormulaList(val);
                                        }}
                                      />
                                    );
                                  })}
                                </SortableGrid>
                              </SortableContext>
                            </DndContext>
                          </Grid>
                        )}
                        {allowSortOrderFormula && (
                          <Grid item xs={12} className="mt-20 p-10">
                            <Grid container justifyContent={"flex-end"}>
                              <Button
                                variant="contained"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setAllowSortOrderFormula(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="ml-10"
                                size="small"
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {sideNavData.dataSource !== COLUMN_DATA_TYPES.FORMULA &&
                    addType === "column" && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          border: "1px grey solid",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                        className="mt-20"
                      >
                        <Grid container>
                          {" "}
                          <Grid item xs={12} className="mt-20">
                            <Grid container justifyContent={"space-between"}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Date Range
                                </Typography>
                                <Select
                                  className="mt-10"
                                  fullWidth
                                  size="small"
                                  value={sideNavData?.dateType}
                                  labelId="date-type-label"
                                  onChange={({ target }) => {
                                    handleSideNavChanges(
                                      "dateType",
                                      target.value
                                    );
                                  }}
                                >
                                  {Object.keys(DATE_RANGE_TYPES)?.map((key) => {
                                    return (
                                      <MenuItem
                                        key={key}
                                        value={DATE_RANGE_TYPES[key]}
                                      >
                                        {DATE_RANGE_TYPES[key]}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                          {sideNavData.dateType === DATE_RANGE_TYPES.MONTH && (
                            <Grid item xs={6} className="mt-20">
                              <DatePicker
                                format={"MMM YYYY"}
                                style={{ width: "100%" }}
                                allowClear={false}
                                size="large"
                                value={dayjs(sideNavData?.start)}
                                picker="month"
                                onChange={(date, dateString) => {
                                  handleSideNavChanges("start", dateString);
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              />
                            </Grid>
                          )}
                          {sideNavData.dateType === DATE_RANGE_TYPES.CUSTOM && (
                            <Grid item xs={6} className="mt-20">
                              <RangePicker
                                size="large"
                                format={"MMM YY"}
                                picker="month"
                                value={[
                                  dayjs(sideNavData.start),
                                  dayjs(sideNavData.end),
                                ]}
                                onChange={(val) => {
                                  handleSideNavChanges("date", {
                                    start: val[0].format("YYYY-MM"),
                                    end: val[1].format("YYYY-MM"),
                                  });
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              ></RangePicker>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-20 p-10">
              <Grid container justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCloseSideNav}
                >
                  Cancel
                </Button>
                <Button
                  disabled={allowSortOrderFormula}
                  variant="contained"
                  className="ml-10"
                  onClick={() => {
                    handleSideNavSave();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <Grid container className="full-container" style={{ height: "90vh" }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Custom report
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={"right"}>
              <Grid item xs={1} lg={1} xl={1} className="mr-10">
                <Grid container justifyContent={"right"}>
                  <IconButton id="button-clientEntityView" color="inherit">
                    <SettingsOutlined
                      style={{
                        color: "grey",
                        fontSize: 20,
                      }}
                      onClick={(event) => handleMenuItemClick(event)}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuItemClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setAddType("row");
                        setSideNavOpen(true);
                        handleMenuItemClose();
                      }}
                    >
                      <Typography className="menuItemTypography">
                        Add row
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAddType("column");
                        setSideNavOpen(true);
                        handleMenuItemClose();
                      }}
                    >
                      <Typography className="menuItemTypography">
                        Add column
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={5} lg={4} xl={2} className="mt-2-5">
                <RangePicker
                  format={"MMM YY"}
                  picker="month"
                  value={[dayjs(dateFilter.start), dayjs(dateFilter.end)]}
                  onChange={(val) => {
                    setDateFilter({
                      start: val[0].format("YYYY-MM"),
                      end: val[1].format("YYYY-MM"),
                    });
                  }}
                ></RangePicker>
              </Grid>
            </Grid>
          </Grid>

          {!isFetching && reportData?.rows?.length !== 0 && reportData.rows && (
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToHorizontalAxis]}
              onDragEnd={handleDragEnd1}
              sensors={sensors1}
            >
              <div className="p-2">
                <table
                  style={{
                    borderSpacing: "0px",
                    display: "inline-block",
                    overflow: "auto",
                    height: "80vh",
                    width: "90vw",
                  }}
                >
                  <thead>
                    {table.getHeaderGroups().map((headerGroup, index) => (
                      <tr className="tr-sticky" key={headerGroup.id}>
                        <SortableContext
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          {headerGroup.headers.map((header) => (
                            <DraggableTableHeader
                              key={header.id}
                              header={header}
                              columns={reportData.columns}
                              className={
                                header.id === "name" ? "th-sticky" : ""
                              }
                            />
                          ))}
                        </SortableContext>
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <SortableContext
                            key={cell.id}
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            <DragAlongCell key={cell.id} cell={cell} />
                          </SortableContext>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </DndContext>
          )}
          {isFetching && <Loading marginTop="10vh" />}
        </Grid>
      </div>
    </div>
  );
};

export default CustomReportPlayGround;

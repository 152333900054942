import React, { useEffect, useState } from "react";
import "./Login.css";
import { Formik } from "formik";
import {
  TextField,
  Link,
  Button,
  Divider,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, reset } from "../../../redux/User/UserSlice";
import xero from "../../../assets/xero.svg";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ColorPalette } from "../../../constants/colorPalette";
export default function Login() {
  const { REACT_APP_BASE_API_URL } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { isSuccess, isError } = useSelector((state) => state.user);

  const location = useLocation();
  const data = location.state;
  const onSubmit = (data) => {
    dispatch(loginUser(data));
    setSubmitting(true);
  };

  useEffect(() => {
    dispatch(reset());
    if (data && data.isLoggedOut) {
      window.history.replaceState({}, "", location.pathname);
    }
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          navigate("/");
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [data, dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
      setSubmitting(false);
    }
    if (isSuccess) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  const xeroConnect = async () => {
    const response = await axios({
      method: "post",
      url: REACT_APP_BASE_API_URL + "/auth/xero-connect",
    });
    window.location.href = response.data.url;
  };
  return (
    <div
      style={{
        // backgroundImage: url(`${background}`),
        height: window.innerHeight,
      }}
    >
      <div className="login-wrapper">
        <Formik
          initialValues={{
            email: localStorage.getItem("email") ?? "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.isEmailEmpty = true;
              errors.email = "Required Field";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.isPassowrdEmpty = true;
              errors.password = "Required Field";
            }
            if (
              values.password.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
            ) {
              errors.password =
                "Invalid Password Pattern! Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            onSubmit(data, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="form-wrapper-login" onSubmit={handleSubmit}>
              <div
                style={{
                  paddingTop: "13%",
                  paddingBottom: "5%",
                  alignContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                  alt="Logo"
                />
              </div>
              <Grid justifyContent="center" container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.email && !!touched.email}
                    id="input-emailAddress"
                    variant="outlined"
                    label="Email"
                    fullWidth
                    size="medium"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    helperText={
                      errors.email &&
                      touched.email &&
                      !errors.isEmailEmpty &&
                      errors.email
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      shrink={!!values.password}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="password"
                      notched={!!values.password}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      error={!!errors.password && !!touched.password}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: !!values.password }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                    pl={1}
                  >
                    {errors.password && !!touched.password && errors.password}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={9}
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Link
                  id="link-forgotPassword"
                  underline="none"
                  onClick={() => {
                    navigate("../forgot-password");
                  }}
                >
                  <h5 className="forgot-password custom mt-10">
                    Forgot your password?
                  </h5>
                </Link>
              </Grid>
              <Grid container justifyContent="center" my={4}>
                <Grid item xs={9}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <LoadingButton
                        id="button-login"
                        loading={submitting}
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={submitting}
                        className="mt-10"
                        sx={{ paddingY: "8px" }}
                      >
                        Login
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider sx={{ flexGrow: 1 }} />
                      <Typography variant="body2" sx={{ mx: 2, color: "gray" }}>
                        Or
                      </Typography>
                      <Divider sx={{ flexGrow: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className="text-normal"
                        variant="outlined"
                        fullWidth
                        onClick={xeroConnect}
                        startIcon={
                          <img
                            style={{ height: "25px", width: "25px" }}
                            alt="xero"
                            src={xero}
                          />
                        }
                      >
                        Sign In With Xero
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import React from "react";
import "./SpreadSheet.css";
import { ReactGrid } from "@silevis/reactgrid";
import { CollapsibleCell } from "./components/CollapsibleCell";
import { ActionCell } from "./components/ActionCell";
import { AccountMappingCell } from "./components/AccountMappingCell";
import { MappingSpanCell } from "./components/MappingSpan";
import { DateCell } from "./components/DateCell";

export default function SpreadSheetTable(props) {
  const {
    rows,
    columns,
    handleColumnResize,
    handleCellChange,
    // handleContextMenu,
    type,
    readOnly = false,
  } = props;
  return (
    <div
      className={
        readOnly ? "spreadsheet-container-report" : "spreadsheet-container"
      }
      style={{ width: "fit-content" }}
      id={type}
    >
      <ReactGrid
        rows={rows}
        columns={columns}
        onColumnResized={handleColumnResize}
        stickyLeftColumns={columns?.length === 3 || type === "cash" ? 2 : 3}
        stickyTopRows={1}
        enableFillHandle
        customCellTemplates={{
          collapsible: new CollapsibleCell(),
          action: new ActionCell(),
          accountMapping: new AccountMappingCell(),
          mapData: new MappingSpanCell(),
          dateRange: new DateCell(),
        }}
        onCellsChanged={(val) => {
          handleCellChange(val);
        }}
        // onContextMenu={handleContextMenu}
      />
    </div>
  );
}

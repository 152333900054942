import React from "react";
import { Grid, Button, Box } from "@mui/material";
import NoDataImg from "../../assets/no-data-found.svg";
import AddIcon from "@mui/icons-material/Add";

export default function NoDataView(props) {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: props?.height || "80vh" }}
    >
      <Grid item>
        <img height={400} width={400} src={NoDataImg} alt="No Data" />
      </Grid>
      <Grid item>
        <label style={{ color: "#0F71FD", fontWeight: "bold" }}>
          {props.hintText}
        </label>
      </Grid>
      <Grid item>
        {props.showButton && (
          <Box display={"flex"} justifyContent="flex-end">
            <Button
              style={{
                backgroundColor: "#06A561",
              }}
              onClick={() => props.addFunction(true)}
              variant="contained"
              startIcon={<AddIcon />}
            >
              {props.buttonText}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ColorPalette } from "../../../constants/colorPalette";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userProfile } from "../../../redux/User/UserSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const UserProfile = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const fileInputRef = useRef();
  const formikRef = useRef(null);
  const { accountingFirmId } = useParams();
  const [value, setValue] = useState("1");
  const [formValues, setFormValues] = useState(null);
  const [passwordValue, setPasswordValue] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;
  const userData = localStorage.getItem("user_data");
  const stateData = checkDataAvailibility(users);
  const [initialValues] = useState({
    firstName: stateData.name?.split(" ")[0],
    lastName: stateData.name?.split(" ")[1],
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    file: null,
  });
  const [resetFormValue, setResetFormValue] = useState(null);

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  useEffect(() => {
    setResetFormValue(() => () => {
      formikRef.current?.resetForm();
    });
  }, []);

  const handleIconClick = (setFieldValue) => {
    if (fileInputRef.current) {
      setFieldValue("file", null);
      fileInputRef.current.click();
      return;
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (resetFormValue) {
      resetFormValue();
    }
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("profilePic", formValues.file);
      formData.append("id", JSON.parse(userData).userId);
      formData.append("firstName", formValues.firstName);
      formData.append("lastName", formValues.lastName);
      formData.append("currentPassword", formValues.currentPassword);
      formData.append("password", formValues.newPassword);
      formData.append("passwordConfirm", formValues.confirmNewPassword);
      dispatch(userProfile({ formData, accountingFirmId }));
      if (resetFormValue) {
        resetFormValue();
      }
      setConfirmModalOpen(false);

      setConfirmModalOpen(false);
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure you want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};

          // File validation
          if (value === "1") {
            if (values.file) {
              if (values.file.size >= fileSize) {
                errors.file = "Please upload an image smaller than 2MB";
              }
            }
            if (!values.firstName) {
              errors.firstName = "Please enter first name";
            }

            if (!values.lastName) {
              errors.lastName = "Please enter last name";
            }
            if (
              values.firstName.length !== 0 &&
              /[^a-zA-Z0-9]/g.test(values.firstName)
            ) {
              errors.firstName = "Special characters not allowed";
            }
            if (
              values.lastName.length !== 0 &&
              /[^a-zA-Z0-9]/g.test(values.lastName)
            ) {
              errors.lastName = "Special characters not allowed";
            }
          }

          if (value === "2") {
            if (!values.currentPassword) {
              errors.currentPassword = "Please enter your current password";
            }
            if (!values.newPassword) {
              errors.newPassword = "Please enter your new Password";
            }
            if (
              values.currentPassword &&
              values.currentPassword === values.newPassword
            ) {
              errors.newPassword =
                "The current password and new password cannot be same";
            }
            if (
              values.currentPassword &&
              !values.newPassword &&
              !values.confirmNewPassword
            ) {
              errors.newPassword = "Please enter your new password";
            }
            if (
              values.newPassword.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.newPassword)
            ) {
              errors.newPassword =
                "Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            if (!errors.currentPassword) {
              if (
                values.currentPassword &&
                values.newPassword &&
                !values.confirmNewPassword
              ) {
                errors.confirmNewPassword = "Please enter password to confirm";
              } else if (
                values.currentPassword &&
                values.newPassword !== values.confirmNewPassword
              ) {
                errors.confirmNewPassword = "Passwords do not match.";
              }
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
          setResetFormValue(() => resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",
              margin: "10px",
              borderRadius: "10px",
              padding: "10px",
              paddingBottom: "30px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              height: "88vh",
            }}
          >
            <Grid container p={3}>
              <Typography variant="h4" fontWeight="bold">
                User Profile
              </Typography>
            </Grid>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "30%",
                    }}
                  >
                    <TabList onChange={handleTabChange}>
                      <Tab
                        label="User Details"
                        value="1"
                        style={{
                          fontWeight: value === "1" ? "bold" : "",
                          color: "black",
                        }}
                      />
                      <Tab
                        label="Change Password"
                        value="2"
                        style={{
                          fontWeight: value === "2" ? "bold" : "",
                          color: "black",
                        }}
                      />
                    </TabList>
                  </Box>
                  <Grid container>
                    <TabPanel value="1">
                      <Grid container py={2}>
                        <Grid item>
                          <InputLabel className="mb-10">
                            Profile Image
                          </InputLabel>
                          {(values.file && !errors.file) || stateData.imgUrl ? (
                            <img
                              src={
                                values.file
                                  ? URL.createObjectURL(values.file)
                                  : stateData.imgUrl
                              }
                              alt=""
                              width={80}
                              height={80}
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <Avatar
                              src="/broken-image.jpg"
                              alt="Profile Image"
                              sx={{ height: 88, width: 88 }}
                            />
                          )}
                          <Grid
                            item
                            sx={{ position: "relative", bottom: 94, left: 61 }}
                          >
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                setFieldValue("file", e.currentTarget.files[0])
                              }
                            />
                            {values.file && !errors.file ? (
                              <IconButton
                                onClick={() => {
                                  setFieldValue("file", null);
                                  fileInputRef.current.value = null;
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              <div
                                onClick={() => handleIconClick(setFieldValue)}
                              >
                                <CameraAltIcon
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                          {errors.file && (
                            <Typography sx={{ color: ColorPalette.danger }}>
                              {errors.file}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          container
                          display={"flex"}
                          alignItems={"center"}
                          gap={3}
                          mt={3}
                        >
                          <Grid>
                            <InputLabel>First Name</InputLabel>
                            <TextField
                              className="mt-10"
                              name="firstName"
                              size="small"
                              placeholder="First name"
                              value={values.firstName}
                              error={!!errors.firstName && !!touched.firstName}
                              // helperText={touched.firstName && errors.firstName}
                              onChange={handleChange}
                            />
                            <Typography
                              variant="body2"
                              color={"error"}
                              mt={0.5}
                              sx={{ height: 20 }}
                            >
                              {!!errors.firstName &&
                                !!touched.firstName &&
                                errors.firstName}
                            </Typography>
                          </Grid>
                          <Grid>
                            <InputLabel>Last Name</InputLabel>
                            <TextField
                              className="mt-10 "
                              placeholder="Last name"
                              name="lastName"
                              size="small"
                              value={values.lastName}
                              error={!!errors.lastName && !!touched.lastName}
                              onChange={handleChange}
                            />
                            <Typography
                              variant="body2"
                              color={"error"}
                              mt={0.5}
                              sx={{ height: 20 }}
                            >
                              {!!errors.lastName &&
                                !!touched.lastName &&
                                errors.lastName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Grid
                        container
                        display={"flex"}
                        flexDirection={"column"}
                        py={2}
                      >
                        <Grid item>
                          <InputLabel className="mb-10 mt-10">
                            Current Password
                          </InputLabel>
                          <OutlinedInput
                            id="profile-page-password"
                            name="currentPassword"
                            size="small"
                            type={
                              passwordValue.currentPassword
                                ? "text"
                                : "password"
                            }
                            value={values.currentPassword}
                            error={
                              !!errors.currentPassword &&
                              !!touched.currentPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      currentPassword: !state.currentPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.currentPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.currentPassword &&
                                errors.currentPassword}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <InputLabel className="mb-10 mt-10">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            name="newPassword"
                            size="small"
                            type={
                              passwordValue.newPassword ? "text" : "password"
                            }
                            value={values.newPassword}
                            error={
                              !errors.currentPassword &&
                              !!errors.newPassword &&
                              !!touched.newPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      newPassword: !state.newPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.newPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.newPassword && errors.newPassword}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <InputLabel className="mb-10 mt-10">
                            Confirm New Password
                          </InputLabel>
                          <OutlinedInput
                            name="confirmNewPassword"
                            size="small"
                            type={
                              passwordValue.confirmPassword
                                ? "text"
                                : "password"
                            }
                            value={values.confirmNewPassword}
                            error={
                              !errors.currentPassword &&
                              !!errors.confirmNewPassword &&
                              !!touched.confirmNewPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      confirmPassword: !state.confirmPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.confirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.confirmNewPassword &&
                                errors.confirmNewPassword}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Grid>
                </TabContext>

                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={3}
                  pr={10}
                >
                  <Button variant="contained" type="submit" disabled={!dirty}>
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => resetForm()}
                    disabled={!dirty}
                  >
                    Reset
                  </Button>
                </Grid>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserProfile;

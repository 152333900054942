import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  addClientSpace,
  getUsersJoinedByInvitation,
} from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { v4 as uuidv4 } from "uuid";

import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { ColorPalette } from "../../../../constants/colorPalette";
import { Formik } from "formik";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const AddClientSpaceModal = ({
  modalOpen,
  setModalOpen,
  accountingFirmId,
  rows,
}) => {
  const dispatch = useDispatch();
  const users = useSelector(
    (state) => state.clientSpaces.membersJoinedByInvite
  );

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Add Client Space and Manager", "Entity Name and Type"];
  const entityTypes = ["COMPANY", "TRUST", "SOLE TRADER", "PARTNERSHIP"];
  const [entities, setEntities] = useState([
    {
      id: uuidv4(),
      entityName: "",
      entityType: "",
      error: {
        name: null,
        type: null,
      },
    },
  ]);

  const [hasEmptyEntity, setHasEmptyEntity] = useState(true);

  useEffect(() => {
    dispatch(getUsersJoinedByInvitation({ accountingFirmId }));
  }, [accountingFirmId, dispatch]);

  useEffect(() => {
    setHasEmptyEntity(
      entities.some(
        ({ entityName, entityType }) => !entityName.trim() || !entityType.trim()
      )
    );
  }, [entities, hasEmptyEntity]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddAnother = () => {
    setEntities([
      ...entities,
      {
        id: uuidv4(),
        entityName: "",
        entityType: "",
        error: { name: null, type: null },
      },
    ]);
  };

  const handleDelete = (id) => {
    const updatedEntities = entities.filter((entity) => entity.id !== id);
    setEntities(updatedEntities);
  };

  const handleAddEntityChange = (id, fieldName, fieldValue) => {
    const updatedEntities = entities.map((entity) => {
      if (entity.id === id) {
        return {
          ...entity,
          [fieldName]: fieldValue,
          error: {
            ...entity.error,
            [fieldName === "entityName" ? "name" : "type"]: null,
          },
        };
      }
      return entity;
    });
    setEntities(updatedEntities);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = (data) => {
    let hasError = false;
    const payload = {
      clientSpaceName: data.spaceName,
      clientSpaceMembers: [
        {
          user: data.manager,
          role: "MANAGER",
        },
      ],
      clientSpaceEntities: entities,
    };

    //check for empty fields
    const updatedEntities = entities.map((entity) => {
      const error = { name: null, type: null };

      if (!entity.entityName.trim()) {
        error.name = "Entity name is empty";
        hasError = true;
      }
      if (!entity.entityType.trim()) {
        error.type = "Entity type is empty";
        hasError = true;
      }
      return { ...entity, error };
    });

    //check for duplicate names
    const entityNames = updatedEntities.map((entity) =>
      entity.entityName.trim().toLocaleLowerCase()
    );
    const duplicates = entityNames.filter(
      (name, index) => entityNames.indexOf(name) !== index
    );
    if (duplicates.length > 0) {
      updatedEntities.forEach((entity) => {
        if (duplicates.includes(entity.entityName.trim().toLocaleLowerCase())) {
          entity.error.name = "Duplicate entity name";
          hasError = true;
        }
      });
    }

    setEntities(updatedEntities);

    if (hasError) {
      return;
    }

    handleModalClose();
    dispatch(addClientSpace({ payload, accountingFirmId }));
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setActiveStep(0);
    setEntities([{ entityName: "", entityType: "" }]);
  };

  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
      >
        <Grid container flexDirection="column">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={2}
          >
            <Typography fontSize={18} fontWeight="bold">
              {activeStep === 0 ? "Add Client Space" : "Entity Details"}
            </Typography>
            <IconButton
              id="button-addGroupCloseIcon"
              style={{ borderRadius: 0 }}
              size="small"
              onClick={() => {
                handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
          <Formik
            initialValues={{
              spaceName: "",
              manager: "",
              entityName: "",
              entityType: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.spaceName) {
                errors.isSpaceNameEmpty = true;
                errors.spaceName = "Please enter clientspace name";
              } else if (/[!@#$%^&*]/g.test(values.spaceName)) {
                errors.spaceName = "Special characters not allowed";
              }
              if (
                rows.some(
                  (row) =>
                    row.clientSpaceName.toLocaleLowerCase() ===
                    values.spaceName.toLocaleLowerCase().trim()
                )
              ) {
                errors.spaceName =
                  "This client name has already been used. Please use different name.";
              }

              if (!values.manager) {
                errors.isManagerEmpty = true;
                errors.manager = "Please select client space manager";
              }

              return errors;
            }}
            validateOnChange={() => {
              // setHasEmptyEntity(entities.some(hasEmptyFields));
            }}
            onSubmit={(data, { setSubmitting }) => {
              if (activeStep === steps.length - 1) {
                // setModalError(true);

                // if (!hasEmptyEntity) {
                //   setModalError(false);
                handleSubmit(data, setSubmitting);
                // } else {
                //   setModalError(true);
                // }
              } else {
                handleNext();
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  item
                  xs={12}
                  style={{
                    overflowY: "auto",
                    overflowX: "auto",
                    width: "100%",
                  }}
                >
                  <Grid p={2} container>
                    <Grid item xs={12} marginTop={2}>
                      {activeStep === 0 ? (
                        <Grid justifyContent="center" container spacing={2}>
                          <Grid item xs={12} marginBottom={2}>
                            <Typography
                              id="text-addGroupName"
                              fontSize={16}
                              className="mb-10"
                            >
                              Client Space Name
                            </Typography>
                            <TextField
                              id="input-spaceName "
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="spaceName"
                              value={values.spaceName}
                              error={!!errors.spaceName && !!touched.spaceName}
                              onChange={handleChange}
                            />
                            <Typography
                              id="error-spaceRequiredField"
                              variant="caption"
                              color={ColorPalette.danger}
                              pl={1}
                            >
                              {!!errors.spaceName &&
                                !!touched.spaceName &&
                                errors.spaceName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              id="text-addGroupManager"
                              fontSize={16}
                              color={ColorPalette.black}
                              className="mb-10"
                            >
                              Client Space Manager
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Select
                                  fullWidth
                                  size="small"
                                  labelId="manager-user"
                                  id="input-spaceManager"
                                  name="manager"
                                  value={values.manager}
                                  onChange={handleChange}
                                  error={!!errors.manager && !!touched.manager}
                                >
                                  {users?.map((user, index) => {
                                    return (
                                      <MenuItem key={index} value={user._id}>
                                        {user.first_name + " " + user.last_name}
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: ColorPalette.gray,
                                          }}
                                        >
                                          {user.email}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {!!errors.spaceName && !!touched.spaceName && (
                                  <div
                                    id="error-spaceRequiredField"
                                    style={{
                                      fontSize: 12,
                                      color: ColorPalette.danger,
                                      marginLeft: "15px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    {errors.manager}
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid px={2}>
                          <Grid
                            style={{
                              maxHeight: "20vh",
                              overflowY: "auto",
                            }}
                          >
                            {entities.map((entity, index) => (
                              <Grid
                                display="flex"
                                container
                                spacing={2}
                                alignItems="flex-start"
                                justifyContent="space-between"
                              >
                                {/* Entity Name */}
                                <Grid item xs={5.5}>
                                  <Typography
                                    id="text-addEntity"
                                    className="mb-10"
                                    color={ColorPalette.black}
                                  >
                                    Entity
                                  </Typography>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    id="input-entityName"
                                    name="entityName"
                                    error={
                                      entity.error.name && !!entity.error.name
                                    }
                                    variant="outlined"
                                    value={entity?.entityName}
                                    onChange={(e) => {
                                      handleAddEntityChange(
                                        entity.id,
                                        "entityName",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {entity.error.name && (
                                    <Typography
                                      color={"error"}
                                      fontSize={"12px"}
                                      my={1}
                                    >
                                      {entity.error.name}
                                    </Typography>
                                  )}
                                </Grid>

                                {/* Entity Type */}
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  item
                                  xs={5.5}
                                >
                                  <Grid items xs={12}>
                                    <Typography
                                      id="text-addEntityType"
                                      color={ColorPalette.black}
                                      className="mb-10"
                                    >
                                      Type
                                    </Typography>
                                    <Select
                                      size="small"
                                      labelId="entity-type"
                                      id="input-entityType"
                                      value={entity?.entityType}
                                      name="entityType"
                                      fullWidth
                                      error={
                                        entity.error.type && !!entity.error.type
                                      }
                                      onChange={(e) => {
                                        handleAddEntityChange(
                                          entity.id,
                                          "entityType",
                                          e.target.value
                                        );
                                      }}
                                      variant="outlined"
                                    >
                                      {entityTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                          {toTitleCase(type)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {entity.error.type && (
                                      <Typography
                                        color={"error"}
                                        fontSize={"12px"}
                                        my={1}
                                      >
                                        {entity.error.type}
                                      </Typography>
                                    )}
                                  </Grid>

                                  {/* Delete Button */}
                                  {entities.length > 1 && (
                                    <IconButton
                                      id="button-deleteEntityDetails"
                                      style={{
                                        borderRadius: 5,
                                        marginLeft: 20,
                                        color: "white",
                                        backgroundColor: "#FC5A5A",
                                        marginTop: 20,
                                        height: "35px",
                                        width: "40px",
                                      }}
                                      size="mediium"
                                      onClick={() => handleDelete(entity.id)}
                                    >
                                      <DeleteIcon
                                        style={{ height: 23, width: 25 }}
                                      />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          <IconButton
                            id="button-addAnotherEntity"
                            disabled={hasEmptyEntity}
                            style={{
                              borderRadius: 5,
                              fontSize: 16,
                              marginTop: 10,
                              float: "right",
                              color: ColorPalette.white,
                              backgroundColor: hasEmptyEntity
                                ? ColorPalette.gray
                                : ColorPalette.contained,
                              height: 37,
                              width: 120,
                            }}
                            size="small"
                            onClick={() => {
                              handleAddAnother();
                            }}
                          >
                            <AddIcon style={{ marginRight: 3 }} fontSize="20" />
                            Add Another
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      sx={{
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Stepper
                        sx={{
                          marginTop: 5,
                          width: "70%",
                        }}
                        activeStep={activeStep}
                        alternativeLabel
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel className="header-color">
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      <Grid
                        container
                        sx={{ justifyContent: "space-between", mt: 3 }}
                      >
                        <IconButton
                          id="button-stepperBack"
                          style={{ borderRadius: 0, fontSize: 16 }}
                          size="small"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          <ArrowBackIosNewIcon
                            style={{ marginRight: 10 }}
                            fontSize="20"
                          />
                          Back
                        </IconButton>
                        <IconButton
                          id="button-stepperFinishOrNext"
                          style={{ borderRadius: 0, fontSize: 16 }}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                          <ArrowForwardIosIcon
                            style={{ marginLeft: 10 }}
                            fontSize="20"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default AddClientSpaceModal;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpreadSheet,
  getSpreadSheets,
} from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingIndicator/Loading";
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DEFAULT_KEYS, ROW_TYPE } from "../../SpreadSheet/defaultValues";
import { getRowClass, getRowStyle } from "../../SpreadSheet/SpreadSheet";
import _ from "lodash";
import SpreadSheetTable from "../../SpreadSheet/SpreadSheetTable";
import "./CashFlowSpreadsheet.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { monthName } from "../../SpreadSheet/helper";
const { RangePicker } = DatePicker;
const CashFlowTab = () => {
  const dispatch = useDispatch();
  const { entityId, accountingFirmId } = useParams();
  const { spreadsheets, spreadsheet, isFetching } = useSelector(
    (state) => state.spreadsheet
  );
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [reload, setReload] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    dispatch(getSpreadSheets({ entityId, search: "", accountingFirmId }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (spreadsheets.length) {
      setSelectedSpreadsheet(spreadsheets[0]._id);
    }
  }, [spreadsheets]);

  useEffect(() => {
    if (selectedSpreadsheet !== null) {
      dispatch(
        getSpreadSheet({
          entityId,
          spreadSheetId: selectedSpreadsheet,
          accountingFirmId,
        })
      );
    }
    // eslint-disable-next-line
  }, [selectedSpreadsheet]);

  useEffect(() => {
    if (spreadsheet !== null) {
      setSpreadsheetData({
        unCollapsedRows: spreadsheet?.cash?.unCollapsedRows,
        rows: getRows(
          spreadsheet?.cash?.rows,
          spreadsheet?.cash?.headers,
          spreadsheet?.cash?.unCollapsedRows,
          spreadsheet?.cash?.columns
        ),
        value: spreadsheet?.cash?.rows,
        columns: spreadsheet?.cash?.columns,
        headers: spreadsheet?.cash?.headers,
      });
      setDateRange({
        viewStart: spreadsheet.viewStart,
        viewEnd: spreadsheet.viewEnd,
      });
    }
    // eslint-disable-next-line
  }, [spreadsheet]);

  const handleCellChange = (val) => {
    if (val[0].type === "collapsible") {
      let index = spreadsheetData.unCollapsedRows.indexOf(val[0].newCell.id);
      if (index >= 0) {
        let data = _.cloneDeep(spreadsheetData.unCollapsedRows);
        data.splice(index, 1);
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(
            spreadsheetData.value,
            spreadsheetData.headers,
            data,
            spreadsheetData.columns
          ),
        });
      } else {
        let data = [...spreadsheetData.unCollapsedRows, val[0].newCell.id];
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(
            spreadsheetData.value,
            spreadsheetData.headers,
            data,
            spreadsheetData.columns
          ),
        });
      }
    }
  };
  const handleColumnResize = (ci, width) => {
    const updatedColumns = spreadsheetData.columns.map((column) => {
      if (column.columnId === ci) {
        return { ...column, width };
      }
      return column;
    });
    setSpreadsheetData((prevData) => ({
      ...prevData,
      columns: updatedColumns,
    }));
  };

  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line
  }, [spreadsheetData]);

  const getRows = (value, headerRow, unCollapsedRows, columns) => {
    let index = 0;
    const editableRows = [];
    return [
      {
        ...headerRow,
        cells: headerRow.cells.map((val) => {
          return {
            ...val,
            style: moment(val.text).isValid()
              ? { justifyContent: "flex-end" }
              : {},
          };
        }),
      },
      ...value
        .filter(
          (data) =>
            data?.visible !== false &&
            (data.rowType === ROW_TYPE.CUSTOM_METRIC ||
            data?.rowType === ROW_TYPE.TOTAL_ROW ||
            data?.rowType === ROW_TYPE.TITLE_ROW ||
            data?.rowType === ROW_TYPE.CUSTOM_METRIC
              ? true
              : unCollapsedRows?.includes(data?.parentId) ||
                (unCollapsedRows?.includes(data.subParentId) &&
                  (data?.groupParentId
                    ? unCollapsedRows?.includes(data?.groupParentId) &&
                      unCollapsedRows?.includes(data?.subParentId)
                    : true)))
        )
        .map((row, idx) => {
          let data = _.cloneDeep(row);
          if (
            (row.rowType === ROW_TYPE.ACCOUNT_GROUP ||
              row.rowType === ROW_TYPE.CUSTOM_METRIC) &&
            !unCollapsedRows.includes(row.id)
          ) {
            let totalRow = _.cloneDeep(value).find((val) =>
              row.rowType === ROW_TYPE.CUSTOM_METRIC
                ? val.subParentId === row.id &&
                  !val.groupParentId &&
                  val.rowType === ROW_TYPE.SUB_TOTAL_ROW
                : val.groupParentId === row.id &&
                  val.rowType === ROW_TYPE.SUB_TOTAL_ROW
            );
            if (totalRow) {
              Object.keys(row).forEach((key) => {
                if (DEFAULT_KEYS.includes(key)) {
                  totalRow[key] = row[key];
                }
              });
              data = totalRow;
            }
          }
          index++;
          let style = getRowStyle(
            data.rowType,
            data.rowType === ROW_TYPE.METRIC
              ? data.metricType === "Input"
              : data.rowType === ROW_TYPE.ACCOUNT_GROUP
              ? unCollapsedRows.includes(data.id)
                ? true
                : false
              : data.rowType === ROW_TYPE.TOTAL_ROW &&
                data.metricName === "GST on Income"
              ? false
              : data.rowType === ROW_TYPE.ACCOUNT
              ? false
              : true,
            true
          );
          let className = getRowClass(data.rowType, true);
          let rowData = {
            rowId: idx,
            height: 40,
            dataId: value.find((row) => row.id === data.id).id,
            cells: [
              {
                type: "text",
                text: `${index}`,
                nonEditable: true,
                style: {
                  paddingLeft: "12px",
                  ...style,
                  fontSize: "14px",
                  fontWeight: "600",
                },
              },
              {
                type: "collapsible",
                text: data?.metricName.toString(),
                isCustomMetric: data.rowType === ROW_TYPE.CUSTOM_METRIC,
                hasChildren:
                  value.filter((val) => val?.parentId === data.id).length !==
                    0 ||
                  value.filter((val) => val?.subParentId === data.id).length !==
                    0 ||
                  value.filter((val) => val?.metricParentId === data.id)
                    .length !== 0 ||
                  value.filter((val) => val?.groupParentId === data.id)
                    .length !== 0,
                columnType: "cash",
                isExpanded: unCollapsedRows?.indexOf(data?.id) !== -1,
                totalRow: data.rowType === ROW_TYPE.TOTAL_ROW,
                subTotalRow: data.rowType === ROW_TYPE.SUB_TOTAL_ROW,
                titleRow: data.rowType === ROW_TYPE.TITLE_ROW,
                rowType: data.rowType,
                metricType: data.metricType,
                metricName: data.metricName,
                showAdd: false,
                showEdit:
                  data.rowType === ROW_TYPE.ACCOUNT &&
                  data?.subParentId?.includes("other_items"),
                id: data.id,
                style,
                className,
                parentId: data.parentId,
                subParentId: data.subParentId,
                groupParentId: data.groupParentId,
                metricParentId: data.metricParentId,
                readOnly: true,
              },
            ],
          };
          let dateColumns = columns?.reduce((acc, column) => {
            if (!DEFAULT_KEYS.includes(column.columnId)) {
              acc.push(column?.columnId);
            }
            return acc;
          }, []);
          dateColumns.forEach((date) => {
            rowData.cells.push({
              type: "text",
              text:
                parseFloat(data[date]?.value) < 0
                  ? `(${parseInt(
                      Math.abs(data[date]?.value)
                    ).toLocaleString()})`
                  : parseInt(Math.abs(data[date]?.value)).toLocaleString() ||
                    "0",
              nonEditable:
                !editableRows.includes(data?.metricName) ||
                data.rowType === ROW_TYPE.SUB_TOTAL_ROW,
              // "other_items".includes(data?.subParentId ?? ""),
              style: {
                ...style,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              },
            });
          });
          return rowData;
        }),
    ].filter((row) => row !== null);
  };

  const handleDateRangeChange = ({ viewStart, viewEnd }) => {
    let betweenMonths = [];
    var date = moment(viewStart).startOf("month");
    while (date <= moment(viewEnd).startOf("month")) {
      betweenMonths.push(moment(date.format("YYYY-MM")));
      date.add(1, "month");
    }
    let data = betweenMonths.map((date) => {
      return { year: date.year(), month: monthName[date.month() + 1] };
    });
    const additionalColumns = data.map((value) => {
      return {
        columnId: value.month.toLowerCase() + value.year,
        width: 100,
        resizable: true,
      };
    });
    let filteredColumns = spreadsheetData?.columns.filter((column) =>
      ["sno", "title"].includes(column?.columnId)
    );

    const headerAdditionalData = data.map((value) => {
      return {
        text: value.month + " " + value.year,
        type: "header",
      };
    });

    data = data.map((date) => date.month.toLowerCase() + date.year);

    let newColumns = [...filteredColumns, ...additionalColumns];
    let newHeaders = {
      ...spreadsheetData.headers,
      cells: [
        ...spreadsheetData.headers.cells.filter((val) =>
          ["S.N", "Title"].includes(val.text)
        ),
        ...headerAdditionalData,
      ],
    };
    setSpreadsheetData({
      ...spreadsheetData,
      unCollapsedRows: spreadsheetData.unCollapsedRows,
      rows: getRows(
        spreadsheetData.value,
        newHeaders,
        spreadsheetData.unCollapsedRows,
        newColumns
      ),
      columns: newColumns,
      headers: newHeaders,
    });
  };

  return (
    <div>
      {isFetching && <Loading wait={true} />}
      {!isFetching && spreadsheet !== null && spreadsheets.length !== 0 && (
        <Paper
          sx={{
            border: 0,
            borderTop: 0,
            borderRadius: 0,
            boxShadow: "none",
            height: "80vh",
          }}
        >
          <Grid container className=" mt-10 p-20">
            <Grid item xs={6}>
              <Grid container justifyContent={"left"}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Cash Flow
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent={"right"}>
                <Grid item xs={3} className="mr-10">
                  <RangePicker
                    style={{ width: "100%" }}
                    minDate={dayjs(spreadsheet?.durationStart)}
                    maxDate={dayjs(spreadsheet?.durationEnd)}
                    format={"MMM YYYY"}
                    allowClear={false}
                    size="large"
                    value={[
                      dayjs(dateRange?.viewStart),
                      dayjs(dateRange?.viewEnd),
                    ]}
                    picker="month"
                    onChange={(date, dateString) => {
                      setDateRange({
                        viewStart: dateString[0],
                        viewEnd: dateString[1],
                      });
                      handleDateRangeChange({
                        viewStart: dateString[0],
                        viewEnd: dateString[1],
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Spreadsheet"
                    select
                    size="small"
                    fullWidth
                    value={selectedSpreadsheet}
                  >
                    {spreadsheets.map((option) => (
                      <MenuItem
                        key={option._id}
                        onClick={() => {
                          setSelectedSpreadsheet(option._id);
                        }}
                        value={option._id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-20">
              <SpreadSheetTable
                rows={_.cloneDeep(spreadsheetData.rows) || []}
                columns={spreadsheetData.columns || []}
                handleCellChange={handleCellChange}
                handleColumnResize={handleColumnResize}
                type="cash"
                readOnly={true}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      {!isFetching && spreadsheets.length === 0 && (
        <Paper
          sx={{
            border: 0,
            borderTop: 0,
            borderRadius: 0,
            boxShadow: "none",
            height: "80vh",
          }}
        >
          <Grid container className=" mt-10 p-20">
            <Grid item xs={12}>
              <Grid container justifyContent={"right"}>
                <Select></Select>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

export default CashFlowTab;

import React, { useEffect } from "react";
import "./ForgotPassword.css";
import { Formik } from "formik";
import { TextField, Link, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword, reset } from "../../../redux/User/UserSlice";
export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isError, isFetching } = useSelector((state) => state.user);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
    }
    if (isSuccess) {
      dispatch(reset());
      navigate("../login");
    }
  }, [isError, isSuccess, dispatch, navigate]);
  return (
    <div>
      <div className="forgot-password-wrapper">
        <Formik
          initialValues={{ email: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Please enter your email address";
              errors.isEmailEmpty = true;
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Please enter valid email address";
            }
            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            dispatch(forgotPassword(data));
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
          }) => (
            <form
              className="form-wrapper-forgot-password"
              onSubmit={handleSubmit}
            >
              <div
                style={{
                  paddingTop: "8%",
                  paddingBottom: "5%",
                  alignContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                  alt="Logo"
                />
              </div>
              <Grid justifyContent="center" container spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    id="input-forgotPasswordEmail"
                    variant="outlined"
                    label="Email"
                    name="email"
                    size="medium"
                    fullWidth
                    value={values.email}
                    error={!!touched.email && !!errors.email}
                    onChange={handleChange}
                    helperText={touched.email && errors.email && errors.email}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Button
                    id="button-resetPassword"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                    className="mt-10"
                  >
                    {isFetching ? (
                      <CircularProgress style={{ color: "white" }} size={23} />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Grid>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item className="login-link">
                    <Link
                      id="link-backToLogin"
                      onClick={() => navigate("../login")}
                      underline="none"
                    >
                      <h5 className="custom">Back to Login</h5>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

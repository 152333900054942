import React from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ColorPalette } from "../../../../constants/colorPalette";
import { Formik } from "formik";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { updateEntity } from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const EditEntityModal = ({ modalOpen, setModalOpen, editData, rows }) => {
  console.log("rows", rows);
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const entityTypes = ["COMPANY", "TRUST", "SOLE TRADER", "PARTNERSHIP"];

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateEntity({
        accountingFirmId,
        id: editData.id,
        data: {
          entityName: values.entityName,
          entityType: values.entityType,
        },
      })
    );
    setSubmitting(false);
    handleModalClose();
  };
  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
        maxWidth="md"
      >
        <Grid container flexDirection={"column"}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography fontSize={20} fontWeight="bold">
              Edit Entity
            </Typography>
            <IconButton
              id="button-addGroupCloseIcon"
              size="small"
              onClick={() => {
                handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Formik
          initialValues={{
            entityName: editData?.name || "",
            entityType: editData?.type || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.entityName) {
              errors.isEntityNameEmpty = true;
              errors.entityName = "Please enter entity name";
            } else if (/[!@#$%^&*]/g.test(values.entityName)) {
              errors.entityName = "Special characters not allowed";
            } else if (
              rows
                .filter((row) => row.name !== editData.name)
                .some(
                  (row) =>
                    row.name.toLowerCase() ===
                    values.entityName.toLowerCase().trim()
                )
            ) {
              errors.entityName =
                "This entity name has already been used. Please use a different name.";
            }
            if (!values.entityType) {
              errors.isEntityTypeEmpty = true;
              errors.entityType = "Please enter entity type";
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container p={2}>
                <Grid item xs={12} marginTop={2} marginBottom={2}>
                  <InputLabel
                    className="mb-10"
                    id="entity-type"
                    sx={{
                      color:
                        !!errors.entityType && !!touched.entityType
                          ? ColorPalette.black
                          : "",
                      mb: 1,
                    }}
                  >
                    Entity Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="entityName"
                    error={!!errors.entityName && !!touched.entityName}
                    helperText={touched.entityName && errors.entityName}
                    defaultValue={editData?.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} marginTop={2} marginBottom={2}>
                  <InputLabel
                    id="entity-type"
                    sx={{
                      color:
                        !!errors.entityType && !!touched.entityType
                          ? ColorPalette.black
                          : "",
                      mb: 1,
                    }}
                  >
                    Entity Type
                  </InputLabel>
                  <Select
                    id="input-entityType"
                    size="small"
                    labelId="entity-type"
                    value={values.entityType}
                    name="entityType"
                    fullWidth
                    error={!!errors.entityType && !!touched.entityType}
                    onChange={handleChange}
                    defaultValue={editData?.type}
                  >
                    {entityTypes.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {toTitleCase(type)}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.entityType && !!touched.entityType && (
                    <div
                      id="error-requiredField"
                      style={{
                        fontSize: 12,
                        color: ColorPalette.danger,
                        marginLeft: "15px",
                        paddingTop: "3px",
                      }}
                    >
                      {errors.entityType}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container pr={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <Button
                    id="button-confirmModalYes"
                    variant="outlined"
                    sx={{
                      marginRight: "10px",
                      minWidth: "90px",
                      height: "35px",
                    }}
                    onClick={() => setModalOpen(false)}
                  >
                    <Typography sx={{ paddingX: "5px", textTransform: "none" }}>
                      Cancel
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    <Typography
                      sx={{ paddingX: "15px", textTransform: "none" }}
                    >
                      Save
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CustomModal>
    </div>
  );
};

export default EditEntityModal;

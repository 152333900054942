import axios from "axios";
import { showNotification } from "../redux/Notification/NotificationSlice";
import { Table, TableContainer } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useFormikContext } from "formik";

const { REACT_APP_BASE_API_URL } = process.env;

const methods = {
  GET: axios.get,
  POST: axios.post,
  DELETE: axios.delete,
  PATCH: axios.patch,
  PUT: axios.put,
};

export const handleRequest = async (
  endpoint,
  requestData,
  toastIdPrefix,
  thunkAPI,
  successCallback = null,
  method = "POST",
  disabledNotification = false
) => {
  try {
    const axiosMethod = methods[method.toUpperCase()];
    if (!axiosMethod) {
      throw new Error(`Invalid HTTP method: ${method}`);
    }
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (requestData instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    }

    const response = await axios({
      method: method,
      url: `${REACT_APP_BASE_API_URL}${endpoint}`,
      data: requestData,
      headers: headers,
    });
    let { data } = response.data;
    if (data == null) {
      data = response.data;
    }

    if (response.status === 200) {
      if (successCallback && data) {
        successCallback(data);
      }
      if (method !== "GET" && !disabledNotification) {
        const { data, status } = response;
        thunkAPI.dispatch(
          showNotification({
            message: data.message,
            status: status,
            isActive: true,
            toastId: `${toastIdPrefix} - Success - ${
              data[toastIdPrefix]?._id || ""
            }`,
          })
        );
      }
      return thunkAPI.fulfillWithValue(data);
    } else {
      thunkAPI.dispatch(
        showNotification({
          message: response.data.message,
          status: response.status,
          isActive: true,
          toastId: `${toastIdPrefix}Failed`,
        })
      );
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    thunkAPI.dispatch(
      showNotification({
        message: error.response.data.message,
        status: error.response.status,
        isActive: true,
        toastId: `${toastIdPrefix}Failed`,
      })
    );

    return thunkAPI.rejectWithValue(error.response);
  }
};

export const isXeroMap = {
  chartOfAccounts: false,
  // detailedTransaction: false,
  // settings: false,
  // budgets: false,
  // contacts: false,
  transactions: false,
};

export const checkDataAvailibility = (state) => {
  const storageData = {
    name:
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
  };
  const userData = JSON.parse(localStorage.getItem("user_data"))
    ? JSON.parse(localStorage.getItem("user_data"))
    : storageData;
  const accFirmData = JSON.parse(localStorage.getItem("accFirm_data"));
  let availableData = {
    name: userData.name,
    email: state.email ? state.email : localStorage.getItem("email"),
    imgUrl: state.imgUrl ? state.imgUrl : userData.profileImg || "",
    role: state.role ? state.role : localStorage.getItem("role"),
    accFirmName: state.accFirmProfile?.name
      ? state.accFirmProfile?.name
      : accFirmData?.accFirmName,
    accFirmImg: state.accFirmProfile?.accFirmImg
      ? state.accFirmProfile?.accFirmImg
      : accFirmData?.accFirmImg,
  };
  return availableData;
};

export const errorDescriptionTable = () => {
  return (
    <TableContainer sx={{ height: "400px" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#6b6d70" }}>Column</TableCell>
            <TableCell sx={{ backgroundColor: "#6b6d70" }}>Rule</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Employee Name</TableCell>
            <TableCell>
              Must be a string and have more than 3 characters. Duplicate
              employee name with spreadsheet table cannot be uploaded.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pay Type</TableCell>
            <TableCell>Must be either "hourly rate" or "salary".</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>
              Must be a valid date (length should not exceed 8 characters).
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>End Date</TableCell>
            <TableCell>
              Must be a valid date (length should not exceed 8 characters).
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hourly Rate</TableCell>
            <TableCell>Must be a number.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Annual Salary</TableCell>
            <TableCell>Must be a number.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Weekly Hours</TableCell>
            <TableCell>Must be a number.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tax Free Threshold Applied</TableCell>
            <TableCell>
              Must be a boolean value (either "Yes" or "No").
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>STSL Applied</TableCell>
            <TableCell>
              Must be a boolean value (either "Yes" or "No").
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const useFormReset = () => {
  const formikContext = useFormikContext();
  return formikContext ? formikContext.resetForm : undefined;
};

import React, { Fragment } from "react";
import { getCellProperty } from "@silevis/reactgrid";
import { IconButton } from "@mui/material";
import { ROW_TYPE } from "../defaultValues";
import {
  Add,
  Edit,
  ExpandMore,
  KeyboardArrowRight,
  Undo,
} from "@mui/icons-material";

export class CollapsibleCell {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    let isExpanded = false;
    let toggleSideNav = () => {};
    let toggleComment = () => {};
    let toggleAddRow = () => {};
    try {
      isExpanded = getCellProperty(uncertainCell, "isExpanded", "boolean");
    } catch {
      isExpanded = true;
    }
    try {
      toggleSideNav = getCellProperty(
        uncertainCell,
        "toggleSideNav",
        "function"
      );
    } catch {
      toggleSideNav = () => {};
    }
    try {
      toggleComment = getCellProperty(
        uncertainCell,
        "toggleComment",
        "function"
      );
    } catch {
      toggleComment = () => {};
    }
    try {
      toggleAddRow = getCellProperty(uncertainCell, "toggleAddRow", "function");
    } catch {
      toggleComment = () => {};
    }
    let indent = -1;
    try {
      indent = getCellProperty(uncertainCell, "indent", "number");
    } catch {
      indent = 0;
    }
    let hasChildren = false;
    try {
      hasChildren = getCellProperty(uncertainCell, "hasChildren", "boolean");
    } catch {
      hasChildren = false;
    }
    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      value,
      isExpanded,
      indent,
      hasChildren,
      toggleSideNav,
      toggleComment,
      toggleAddRow,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      isExpanded: cellToMerge.isExpanded,
      text: cellToMerge.text,
    });
  }

  getStyle(cell) {
    const style = cell.style || "";
    return {
      ...style,
    };
  }
  getClassName(cell, isInEditMode) {
    const className = cell.className || "";
    return className;
  }

  render(cell, isInEditMode, onCellChanged) {
    return (
      <>
        {cell.parentId && <div style={{ width: 20 }}></div>}
        {cell.subParentId && <div style={{ width: 20 }}></div>}
        {cell.columnType === "cash" &&
          (cell.rowType === ROW_TYPE.ACCOUNT ||
            cell.rowType === ROW_TYPE.ACCOUNT_GROUP) && (
            <div style={{ width: 20 }}></div>
          )}
        {cell.metricParentId && <div style={{ width: 20 }}></div>}
        {cell.groupParentId && <div style={{ width: 20 }}></div>}
        {["GST on Income"].includes(cell?.metricName) && (
          <div style={{ width: 60 }}></div>
        )}
        {["Taxes and Super"].includes(cell?.metricName) && (
          <div style={{ width: 20 }}></div>
        )}
        {[
          "Total Cash Inflows",
          "Total Cash Outflows",
          "Net Cash Flows",
        ].includes(cell?.metricName) && <div style={{ width: 20 }}></div>}

        {!cell.totalRow &&
        !cell.subTotalRow &&
        !cell.titleRow &&
        cell.rowType !== ROW_TYPE.METRIC &&
        (cell.rowType === ROW_TYPE.ACCOUNT
          ? cell.metricType === "Calculation"
            ? true
            : false
          : true) ? (
          <IconButton
            disableRipple
            disabled={!cell.hasChildren}
            style={{ minWidth: "15px" }}
            color="inherit"
            onPointerDown={(e) => {
              e.stopPropagation();
              onCellChanged(
                this.getCompatibleCell({
                  ...cell,
                  isExpanded: !cell.isExpanded,
                  nonEditable: undefined,
                }),
                true
              );
            }}
          >
            {cell.isExpanded ? (
              <ExpandMore style={{ marginTop: "3px" }} />
            ) : (
              <KeyboardArrowRight style={{ marginTop: "3px" }} />
            )}
          </IconButton>
        ) : (
          !cell.totalRow && <div style={{ width: 20 }}></div>
        )}

        <div
          style={{
            overflow: "clip",
            textOverflow: "ellipsis",
          }}
        >
          {cell.text}{" "}
          {cell.valueType === "Percentage"
            ? "(%)"
            : cell.valueType === "Currency"
            ? "($)"
            : ""}
        </div>
        <div id="basic-button" style={{ marginLeft: "auto" }}>
          {cell?.metricName === "Opening Bank Balance" && !cell?.readOnly && (
            <IconButton
              id={"button" + cell.id}
              sx={{ color: "black" }}
              onClick={() => {
                cell.handleUndoManual(cell.id);
              }}
            >
              <Undo fontSize="small" />
            </IconButton>
          )}
          {(cell?.columnType === "cash"
            ? cell.showAdd
            : cell.rowType === ROW_TYPE.CUSTOM_METRIC) && (
            <IconButton
              id={"button" + cell.id}
              disabled={cell.disabled}
              sx={{ color: cell?.columnType === "cash" ? "white" : "black" }}
              onClick={() => {
                cell.handleAddItem(cell.id);
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          )}
          {cell?.columnType === "cash" && cell.showEdit && (
            <IconButton
              id={"button" + cell.id}
              onClick={() => {
                cell.handleEditItem(cell.id);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          )}
        </div>
      </>
    );
  }
}

import { useLocation, Navigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const { children, name } = props;
  const isLoggedIn = localStorage.getItem("accessToken") !== null;
  const accountingFirmId = localStorage.getItem("accountingFirmId");
  const location = useLocation();
  if (
    name === "login" ||
    name === "register" ||
    name === "forgot-password" ||
    name === "reset-password" ||
    name === "verify" ||
    name === "xero-signup"
  ) {
    return isLoggedIn ? (
      <Navigate
        replace={true}
        to={`/accountingFirm/${accountingFirmId}/clientSpaces`}
      />
    ) : (
      <>{children}</>
    );
  } else {
    if (Math.round(Date.now() / 1000) > localStorage.getItem("expiryTime")) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user_data");
      localStorage.removeItem("expiryTime");
      localStorage.removeItem("randid");
      return (
        <Navigate
          replace={true}
          to="/login"
          state={{ from: `${location.pathname}${location.search}` }}
        />
      );
    }
    return isLoggedIn ? (
      <>{children}</>
    ) : (
      <Navigate
        replace={true}
        to="/login"
        state={{ from: `${location.pathname}${location.search}` }}
      />
    );
  }
};
export default PrivateRoute;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import UserIcon from "@mui/icons-material/AccountCircleOutlined";
import logo from "../../assets/formulr-logo.svg";
import { reset } from "../../redux/User/UserSlice";
import { Logout } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { showNotification } from "../../redux/Notification/NotificationSlice";
import { checkDataAvailibility } from "../../helper/utils";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  boxShadow: "0px 1px 5px 0px grey",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppbarCustom(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user);
  const { open, setOpen } = props;
  const handleDrawer = (e) => {
    setOpen(!open);
  };
  const stateData = checkDataAvailibility(data);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const toggle = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          navigate("./login");
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [navigate]);

  const isExtraSmallSize = useMediaQuery("(max-width:600px)");

  return (
    <div onClick={() => setOpen(false)}>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={(e) => {
                e.stopPropagation();
                handleDrawer();
              }}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon style={{ color: "grey" }} />
            </IconButton>
            <Button
              size="small"
              disabled
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <img
                  height={30}
                  // src={stateData.accFirmImg ? stateData.accFirmImg : logo}
                  src={logo}
                  alt="Logo"
                />
                {/* <Typography
                  className="text-normal"
                  fontSize={18}
                  fontWeight="bold"
                >
                  {stateData.accFirmName}
                </Typography> */}
              </div>
            </Button>
            {isExtraSmallSize ? (
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open user toggle"
                  onClick={handleClick}
                  edge="start"
                  sx={{
                    marginLeft: 5,
                  }}
                >
                  {/* {stateData.imgUrl ? (
                    <img
                      height={40}
                      width={40}
                      style={{ borderRadius: "50%" }}
                      src={stateData.imgUrl}
                      alt="profile-img"
                    />
                  ) : ( */}
                  <UserIcon style={{ color: "grey" }} />
                  {/* )} */}
                </IconButton>
                <Menu
                  id="user-menu"
                  aria-labelledby="user-menu"
                  anchorEl={anchorEl}
                  open={toggle}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>{stateData.name}</MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/xeroAuthentication");
                    }}
                  >
                    Xero
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      dispatch(reset());
                      navigate("./login", { state: { isLoggedOut: true } });
                      handleClose();
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <span>Logout</span>{" "}
                      <Logout style={{ marginLeft: 10 }}></Logout>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div style={{ marginLeft: "auto", display: "flex", gap: 7 }}>
                <Grid
                  container
                  xs={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item>
                    {/* {stateData.imgUrl ? (
                      <img
                        height={40}
                        width={40}
                        style={{ borderRadius: "50%" }}
                        src={stateData.imgUrl}
                        alt="profile-img"
                      />
                    ) : null} */}
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "grey",
                        marginTop: "10px",
                        marginLeft: "5px",
                      }}
                      fontWeight={"bold"}
                    >
                      {stateData.name}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Button
                  style={{ color: "grey" }}
                  size="small"
                  onClick={() => {
                    navigate("/xeroAuthentication");
                  }}
                >
                  xero
                </Button> */}
                <Button
                  style={{ color: "grey" }}
                  size="small"
                  onClick={() => {
                    localStorage.clear();
                    dispatch(
                      showNotification({
                        message: "Successfully logged out",
                        status: 200,
                        isActive: true,
                        toastId: "logout",
                      })
                    );
                    dispatch(reset());
                    navigate("./login", { state: { isLoggedOut: true } });
                  }}
                >
                  <Logout fontSize="medium"></Logout>
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
